body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: "Britanica";
    src: local("Britanica"), url("./fonts/Britanica.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Medium";
    src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.custom-title {
    font-family: "Poppins-Medium";
    font-smooth: antialiased;
    text-rendering: optimizeLegibility;
    font-weight: bold;
    font-size: 44px;
    white-space: nowrap;
}

.custom-header {
    margin-left: 15px;
    font-family: "Poppins-Medium";
    font-smooth: antialiased;
    text-rendering: optimizeLegibility;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #f0f0f0;
    white-space: nowrap;
}

.background-container-wave {
    background: url("./animatedWave.svg") center/cover no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center bottom; */
    height: 100%;
    min-height: 100vh;

    /* Responsive styles for small screens */
    @media (max-width: 600px) {
        height: 100%;
    }

    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    font-size: 3px;
    text-align: center;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f0f0; /* Background color for better visibility */
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.imageWithText {
    text-align: center;
    height: 20%;
    width: 20%;
    align-items: center;
}

.overview-stats-card {
    fontfamily: "Poppins-Medium, sans-serif";
}

.App {
    text-align: center;
    margin-top: 50px;
}

/* Tabs */

.tabsContainer {
    display: flex;
    justify-content: center; /* Center the tabs horizontally */
}

.tab {
    padding: 20px;
    margin: 1px; /* Adjust the margin between tabs as needed */
    cursor: pointer;
}

.react-tabs__tab-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.react-tabs__tab {
    cursor: pointer;
    padding: 10px;
    margin: 0;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.react-tabs__tab--selected {
    background-color: #eee;
    border-color: #ccc;
}

.react-tabs__tab-panel {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 0 0 4px 4px;
}

.faqs {
    max-width: 100%;
    position: absolute;
}

.faqs .faq {
    margin: 15px;
    padding: 15px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    width: calc(100% - 30px); /* Ensure each FAQ box fits within the container */
    max-width: 96%; /* Limit the maximum width of the FAQ box */
}

.faqs .faq .faq-question {
    position: relative;
    padding-right: 30px; /* Increased padding for better spacing */
    font-size: 16px;
}

.faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 15px; /* Adjusted the position of the plus sign */
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    font-size: 20px; /* Increased font size for better visibility */
    text-align: center; /* Centered the plus sign */
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    font-size: 15px;
    color: rgb(124, 124, 124);
    font-style: italic;
    transition: max-height 0.4s ease, opacity 0.4s ease; /* Added transition for smoother animation */
}

.faqs .faq.open .faq-question::after {
    content: "-";
}

.faqs .faq.open .faq-answer {
    max-height: 1000px; /* Increased max-height for better visibility */
    opacity: 1;
}
